import { api } from "@data-phone/react-resource-server-auth";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getProviders = createAsyncThunk(
    "items/fetchProviders",
    async () => {
        const response = await api.get(
          `${process.env.REACT_APP_CONTACTS_URL}/provider`,
        );
        return response.data;
    }
  );

const initialState = {
  data: []
};

const providers = createSlice({
  name: "providers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProviders.fulfilled, (state, action) => {
        state.data = action.payload;
      })
  },
});

export default providers.reducer;
