import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axiosAuthRefresh from 'axios-auth-refresh';
// import { fetchUserData, logoutUser } from '../redux/slices/userSlice';

// axios instance
const instance = axios.create({
  baseURL: 'https://contacts.maqsood-local.dataphone.cloud',
});

const AxiosInterceptor = ({ children }) => {
  const [isSet, setIsSet] = useState(false);
  const [pendingRequests, setPendingRequests] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      if (error.response.status === 401 && !error.config._retry) {
        error.config._retry = true;
        const originalRequest = error.config;
        setPendingRequests((prevRequests) => [
          ...prevRequests,
          originalRequest,
        ]);

        // Trigger token refresh
        axiosAuthRefresh(instance, refreshAuthLogic);
      }
      return Promise.reject(error);
    };

    const refreshAuthLogic = async (failedRequest) => {
      // Code to refresh token and retry the failed request
      try {
        // Example: Refresh token using your API endpoint
        await axios.get(`${process.env.REACT_APP_AUTH_URL}/auth/refresh`, {
          withCredentials: true,
        });
        instance.defaults.withCredentials = true;
        // const refreshResponse = {};
        // dispatch(fetchUserData());
        pendingRequests.forEach((request) => {
          instance(request);
        });

        // Clear the list of pending requests
        setPendingRequests([]);

        // Retry the original failed request
        return Promise.resolve();
      } catch (error) {
        // Handle token refresh failure
        console.log('Token refresh failed', error);
        // You might want to dispatch a logout action here
        // dispatch(logoutUser());
        return Promise.reject(error);
      }
    };

    // Attach the interceptors
    instance.interceptors.response.use(resInterceptor, errInterceptor);

    // Setup axios-auth-refresh
    axiosAuthRefresh(instance, refreshAuthLogic);

    setIsSet(true);

    // Clean up the interceptors on unmount
    return () => {
      instance.interceptors.response.eject(resInterceptor);
    };
  }, []);

  return isSet && children;
};

export default instance;
export { AxiosInterceptor };
