import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { api } from "@data-phone/react-resource-server-auth";
import cogoToast from "cogo-toast";
import { act } from "react";
import { generateGoogleLink } from "./googleAuth";


export const getUserContacts = createAsyncThunk(
  "items/fetchUserContacts",
  async () => {
    const response = await api.get(
      `${process.env.REACT_APP_CONTACTS_URL}`,
    );
    return response.data;
  }
);

export const syncContactsApi = createAsyncThunk(
  "items/fetchSyncContacts",
  async (type) => {
    try {
      const response = await api.get(
        `${process.env.REACT_APP_CONTACTS_URL}/sync/54e07dd0-7d56-443a-b129-60d0ae682ed5`,
      );
      return response.data;
    } catch (error) {
      if (error.response.data.errors === "Google Auth Error") {
        generateGoogleLink(window.location.origin);
        return
      }
    }

  }
)

export const createGoogleContact = createAsyncThunk(
  "items/fetchCreateGoogleContact",
  async (data) => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_CONTACTS_URL}`,
        data.contact,
      );
      cogoToast.success("Contact created successfully");

      return {
        data: response.data,
        navigate: data.navigate,
      };

    } catch (error) {
      if (error.response.data.errors === "Google Auth Error") {
        generateGoogleLink(window.location.origin);
        return
      }
    }
  }
);

export const updateContact = createAsyncThunk(
  "items/fetchUpdateContact",
  async (data) => {
    try {
      const response = await api.put(
        `${process.env.REACT_APP_CONTACTS_URL}/${data.id}`,
        data.contactData,
        { withCredentials: true }
      );
      cogoToast.success("Contact updated successfully");

      return {
        data: response.data,
        navigate: data.navigate,
      };

    } catch (error) {
      if (error.response.data.errors === "Google Auth Error") {
        generateGoogleLink(window.location.origin);
        return
      }
    }

  }
);

export const deleteContact = createAsyncThunk(
  "items/fetchDeleteContact",
  async (data) => {
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_CONTACTS_URL}/${data.contactId}`,
      );
      return {
        id: data.contactId,
        navigate: data.navigate,
      };
    } catch (error) {
      if (error.response.data.errors === "Google Auth Error") {
        generateGoogleLink(window.location.origin);
        return
      }
    }
  }
);

export const updateLocalToGoogleContact = createAsyncThunk(
  "items/fetchUpdateLocalToGoogleContact",
  async (data) => {
    try {

      const response = await api.get(
        `${process.env.REACT_APP_CONTACTS_URL}/sync/google/${data.contactId}`
      );
      return {
        data: response.data,
        navigate: data.navigate,
      };
    } catch (error) {
      if (error.response.data.errors === "Google Auth Error") {
        generateGoogleLink(window.location.origin);
        return
      }
    }
  }
);

const initialState = {
  data: [],
  status: "idle",
  error: null,
  googleSyncStatus: "idle",
  deleteContactStatus: "idle",
  updateLocalToGoogleStatus: "idle",
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserContacts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUserContacts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(getUserContacts.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(createGoogleContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGoogleContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.data.id) {
          let contacts = [...state.data, action.payload.data];
          state.data = contacts;

          action.payload.navigate(
            `/contact/${action.payload.data.id}`
          );
        }
      })
      .addCase(createGoogleContact.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateContact.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.status = "succeeded";
        console.log(action.payload)
        if (action.payload.data.id) {
          let contacts = [...state.data];
          let index = contacts.findIndex(
            (contact) =>
              contact.id ==
              action.payload.data.id
          );
          contacts[index] = action.payload.data;
          state.data = contacts;
          action.payload.navigate(
            `/contact/${action.payload.data.id}`
          );
        }
      })
      .addCase(updateContact.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(deleteContact.pending, (state) => {
        state.deleteContactStatus = "loading";
      })
      .addCase(deleteContact.fulfilled, (state, action) => {
        state.deleteContactStatus = "succeeded";
        cogoToast.success("Contact deleted successfully");
        action.payload.navigate(`/`);
        if (action.payload.id) {
          let contacts = [...state.data];
          let index = contacts.findIndex(
            (contact) => contact.id == action.payload.id
          );
          contacts.splice(index, 1);
          state.data = contacts;
        }
      })
      .addCase(deleteContact.rejected, (state, action) => {
        state.deleteContactStatus = "failed";
      })
      .addCase(updateLocalToGoogleContact.pending, (state) => {
        state.updateLocalToGoogleStatus = "loading";
      })
      .addCase(updateLocalToGoogleContact.fulfilled, (state, action) => {
        state.updateLocalToGoogleStatus = "succeeded";
        if (action.payload.data) {
          action.payload.navigate(`/contact/${action.payload.data.id}`);
        }
        cogoToast.success("Contact updated successfully");
      })
      .addCase(updateLocalToGoogleContact.rejected, (state, action) => {
        state.updateLocalToGoogleStatus = "failed";
      })
      .addCase(syncContactsApi.pending, (state) => {
        state.googleSyncStatus = "loading";
      })
      .addCase(syncContactsApi.fulfilled, (state, action) => {
        state.googleSyncStatus = "succeeded";
        state.data = [...state.data, ...action.payload];
        cogoToast.success("Contacts synced successfully");
      })
      .addCase(syncContactsApi.rejected, (state, action) => {
        state.googleSyncStatus = "failed";
      });
  },
});

export default contactsSlice.reducer;
