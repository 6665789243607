import ContactView from   "components/Contacts/ContactView"
import ContactEdit from   "components/Contacts/ContactEdit"
import ContactList from   "components/Contacts/ContactList"
import PageNotFound from "pages/404"
import AddContact from "components/Contacts/AddContact"

const routes = [
    {
        path:"",
        component: ContactList,
        title:"Contacts"
    },
    {
        path:"/add",
        component: AddContact,
        title:"Contact"
    },
    {
        path:"/contact/:id",
        component: ContactView,
        title:"Contact"
    },
    {
        path:"/contact/edit/:id",
        component: ContactEdit,
        title:"Contact"
    },
    {
        path:"*",
        component:PageNotFound,
        title:"Page Not Found"
    }

]

export default routes