import { parsePhoneNumber } from "libphonenumber-js";

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};


function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

export function generateNameSpaceColor(namespace, lightness = 90) {
  const hash = hashCode(namespace);
  const hue = hash % 360; // Use hash code to determine the hue value (0 to 359)
  const color = `hsl(${hue}, 100%, ${lightness}%)`; // Set the lightness component for a lighter color

  return color;
}

export function generateLightColorByLetter(letter = "N") {
  // get first alphabet in upper case
  const firstAlphabet = letter.charAt(0).toLowerCase();

  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);

  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();

  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = num >> 16 & 255;
  var g = num >> 8 & 255;
  var b = num & 255;

  return {
    color: 'rgb(' + r + ', ' + g + ', ' + b + ', 0.3)',
    character: firstAlphabet.toUpperCase()
  };
}


export const formatPhoneNumber = (number) => {
  try {
    const phoneNumber = parsePhoneNumber(number);
    return phoneNumber?.formatNational();
  } catch (error) {
    return number
  }
};