import { useNavigate } from "react-router-dom";
import { MdDelete, MdOutlineModeEditOutline } from "react-icons/md";
import { FcGoogle } from "react-icons/fc";
import { generateNameSpaceColor } from "../../helper/index";
import { ImSpinner8 } from "react-icons/im";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteContact } from "redux/slices/contacts";


const ContactCard = ({ name, number,contact_key, id, type ,provider_name}) => {
  const navigate = useNavigate();
  let color = generateNameSpaceColor(name);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const contact = useSelector((state) => state.contacts);
  const dispatch = useDispatch();

  const handleDelete = () => {
    dispatch(
      deleteContact({
        contactId: deleteId,
        type: type,
        navigate: navigate,
      })
    );
  }

  return (
    <>
      <div
        id="popup-modal"
        tabindex="-1"
        className={`${deleteModal ? "" : "hidden"
          } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
      >
        <div
          style={{ margin: "auto", marginTop: "20%" }}
          className="relative p-4 w-full max-w-md max-h-full"
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button
              type="button"
              onClick={() => {
                setDeleteModal(false);
              }}
              className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="popup-modal"
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
            <div className="p-4 md:p-5 text-center">
              <svg
                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                Are you sure you want to delete this contact?
              </h3>
              <button
                data-modal-hide="popup-modal"
                type="button"
                disabled={contact.deleteContactStatus == "loading"}
                onClick={() => {
                  handleDelete();
                }}
                className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center me-2"
              >
                {contact.deleteContactStatus == "loading" && (
                  <ImSpinner8
                    className="animate-spin"
                    size={16}
                    color="white"
                  />
                )}
                {contact.deleteContactStatus !== "loading" && (
                  <span>Yes, delete</span>
                )}
              </button>
              <button
                data-modal-hide="popup-modal"
                type="button"
                onClick={() => {
                  setDeleteModal(false);
                }}
                className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
              >
                No, cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <tr
        onClick={() => {
          navigate(`/contact/${id}`);
        }}
        role="button"
        className="hover:bg-[#f5f5f5] group relative"
      >
        <td className="flex items-center gap-5 px-4">
          <div className="w-full flex">
            {/* <img
            style={{ margin: "10px" }}
            className="w-9 h-9 rounded-full"
            src={photo}
          /> */}
            {
              <div
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden  rounded-full "
                style={{
                  backgroundColor: color,
                  margin: "10px",
                }}
              >
                <span className="font-medium uppercase ">
                  {name[0] ? name[0] : ""}
                </span>
              </div>
            }

            <span className="py-4">{name}</span>
          </div>
        </td>
        <td className="px-4">{number}</td>
        <td className="px-4">{contact_key}</td>
        <td className="px-4">
          <span
            className="text-xs inline-flex items-center font-medium me-2 px-2.5 py-0.5 rounded justify-center w-24"
            style={{
              border: `2px solid ${type == "GOOGLE" ? "blue" : "gray"}`,
            }}
          >
          {provider_name?provider_name:"LOCAL"}
          </span>
        </td>
        <td className="px-4">
          <div className="hidden group-hover:visible  absolute top-0  h-full gap-3 sm:flex ">
            <button
              className="h-full"
              onClick={(e) => {
                // Prevent parent handlers from firing
                e.stopPropagation();
                navigate(`/contact/edit/${id}`);
              }}
            >
              <MdOutlineModeEditOutline
                className="text-[#00000051] hover:text-black"
                size={24}
              />
            </button>
            <button
              onClick={(e) => {
                // Prevent parent handlers from firing
                e.stopPropagation();
                setDeleteModal(true);
                setDeleteId(id);
              }}
            >
              {" "}
              <MdDelete
                className="text-[#00000051] hover:text-black"
                size={24}
              />
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default ContactCard;
