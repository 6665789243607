import { combineReducers } from 'redux'
import usersDataSlice from "./userSlice"
import googleAuth from './googleAuth'
import contactsSlice from "./contacts"
import providers from './providers'

const rootReducer = combineReducers({
  usersDataSlice:usersDataSlice,
  googleAuth:googleAuth,
  contacts:contactsSlice,
  providers:providers
})

export default rootReducer