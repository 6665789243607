import { IoMdArrowBack } from "react-icons/io";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";

import PhoneView from "./PhoneView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getSingleContact } from "./APIs/contactApis";
import cogoToast from "cogo-toast";
import {
  deleteContact,
  updateLocalToGoogleContact,
} from "redux/slices/contacts";
import { ImSpinner, ImSpinner8 } from "react-icons/im";
import { googleAuthLogin } from "redux/slices/googleAuth";
import { generateNameSpaceColor } from "helper";
import { FcGoogle } from "react-icons/fc";

const ContactView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const match = useMatch("/contact/:id");
  const contact = useSelector((state) =>
    state.contacts.data.find((contact) => contact.id == params.id)
  );
  const dispatch = useDispatch();
  const contactsData = useSelector((state) => state.contacts);

  const [contactData, setContactData] = useState({});
  const getContact = async (id) => {
    try {
      let singleContact = await getSingleContact(id);
      if (singleContact.data) {
        setContactData(singleContact.data);
      }
      return singleContact;
    } catch (error) {
      if (window?.location.pathname !== "/") {
        cogoToast.error(
          error.response.data.message
            ? error.response.data.message
            : "Some error occured. Please try again later."
        );
      }
      navigate("/");
      throw error;
    }
  };
  useEffect(() => {
    if (contact) {
      setContactData(contact);
    } else {
      getContact(params.id,params.type);
    }
  }, [contact]);

  const deleteContactHandler = async (id) => {
    try {
     
      dispatch(
        deleteContact({
          contactId: id,
          type: params.type,
          navigate: navigate,
        })
      );
    } catch (error) {
      cogoToast.error(
        error.response.data.message
          ? error.response.data.message
          : "Some error occured. Please try again later."
      );
      navigate("/");
      throw error;
    }
  };

  const updateToGoogleContact = () => {
      dispatch(googleAuthLogin({ redirect_url: `${process.env.REACT_APP_URL}`}));
  };
  let color =
    contactData && 
    contactData?.first_name &&
    generateNameSpaceColor(contactData?.first_name);
  return (
    <div>
      <div className="p-8">
        <div className="flex justify-between flex-col sm:flex-row">
          <div className="flex gap-8 flex-col sm:flex-row">
            <div className="flex gap-8">
              <div className="pt-2">
                <button onClick={() => navigate("/")}>
                  <IoMdArrowBack size={28} />
                </button>
              </div>
              <div
                className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden  rounded-full "
                style={{
                  backgroundColor: color,
                  margin: "10px",
                  height: "100px",
                  width: "100px",
                }}
              >
                <h1 className="font-medium uppercase text-3xl">
                {`${contactData?.first_name || ""}`.charAt(0)}
                </h1>
              </div>
            </div>
            <div className="flex items-center justify-center ml-2 my-4">
              <h1 className="text-3xl">
                    {`${contactData?.first_name || ""} ${contactData?.middle_name || ""} ${contactData?.last_name || ""}`}
              </h1>
            </div>
          </div>
          <div className="flex gap-4 justify-center">
            {contactData && params.type == "LOCAL" && (
              <div className="flex justify-end items-end">
                {contactsData.updateLocalToGoogleStatus == "loading" && (
                  <button
                    className={`h-10 bg-[#1a73e8] text-white px-8 rounded disabled:grayscale`}
                    disabled={true}
                  >
                    <span>
                      <ImSpinner className="spin"></ImSpinner>
                    </span>
                  </button>
                )}
                {(contactsData?.updateLocalToGoogleStatus == "idle" ||
                  contactsData.updateLocalToGoogleStatus == "succeeded") && (
                  <button
                    onClick={() => {
                      updateToGoogleContact();
                    }}
                    className="h-10 bg-[#1a73e8] text-white px-8 rounded"
                  >
                    <FcGoogle className="inline-block mr-2" />
                    Sync with google
                  </button>
                )}
              </div>
            )}
            {match && (
              <div className="flex justify-end items-end">
                <button
                  onClick={() => navigate(`/contact/edit/${params.id}`)}
                  className="h-10 bg-[#1a73e8] text-white px-8 rounded"
                >
                  Edit
                </button>
              </div>
            )}

            <div className="flex justify-end items-end">
              <button
                onClick={() => {
                  deleteContactHandler(params.id);
                }}
                disabled={contactsData.deleteContactStatus == "loading"}
                className="h-10 bg-[#d44235] text-white px-8 rounded disabled:grayscale"
              >
                {contactsData.deleteContactStatus == "loading" && (
                  <span>
                    <ImSpinner8 className="spin"></ImSpinner8>
                  </span>
                )}
                {contactsData.deleteContactStatus != "loading" && (
                  <span>Delete</span>
                )}
              </button>
            </div>
          </div>
        </div>
        <hr className="my-8" />
        <div>
          <Outlet />
        </div>
        {contactData &&
          contactData?.first_name &&
          !window.location.pathname.includes("edit") && (
            <PhoneView contact={contactData} />
          )}
      </div>
    </div>
  );
};

export default ContactView;
