import { Link, useNavigate } from 'react-router-dom';

import AddIcon from "../../images/contacts/add.png"
import Logo from "../../images/contacts/logo.png"
import Sync from "../../images/contacts/spin.png"

import SearchBox from './SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { googleAuthLogin } from 'redux/slices/googleAuth';
import "./contacts.css"
import { useState } from 'react';
import { getUserContacts, syncContactsApi, syncGoogleContacts } from 'redux/slices/contacts';
import Cookies from 'js-cookie';




const ContactHeader = ({ filterContacts }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [btnText, setBtnText] = useState("Sync Google Contacts")

  const contacts = useSelector((state) => state.contacts)

  const syncContacts = () => {
      dispatch(syncContactsApi("GOOGLE"))
  }


  return (
    <header className="sticky top-0 bg-white z-10">
      <div className="flex flex-col sm:flex-row sm:items-center pr-4 justify-between  m-auto">
        <Link to={"/"} className="flex px-8 py-3 items-center gap-3">
          <img src={Logo} className="w-10 h-10" />
          <span className="text-[#5f6368] text-xl">Contacts</span>
        </Link>
        <SearchBox filterContacts={filterContacts} />
        <div className='flex'>
          <button
            onClick={() => {
              syncContacts()
            }}
            disabled={contacts.googleSyncStatus == "loading" || contacts.status == "loading" }
            className="px-2 sm:pr-4 py-1 border rounded-full items-center hidden md:flex gap-2 disabled:grayscale shadow hover:shadow-md mr-2"
            style={{ backgroundColor: "aquamarine" }}
          >
            <img src={Sync} className="w-6 h-6 rounded-full" style={{ animation: contacts.googleSyncStatus == "loading"  || contacts.status == "loading"  ? 'spin 1s linear infinite' : "" }}
            />
            <span className="font-medium text-sm lg:text-base hidden sm:block">
              {btnText}
            </span>
          </button>
          <button
            onClick={() => navigate("/add")}
            className="px-2 sm:pr-4 py-1 border rounded-full items-center hidden md:flex gap-2 shadow hover:shadow-md"
          >
            <img src={AddIcon} className="w-9 h-9 rounded-full" />
            <span className="font-medium text-sm lg:text-base hidden sm:block">
              Create contact
            </span>
          </button>
        </div>
      </div>
    </header>
  )
}

export default ContactHeader