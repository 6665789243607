import { Provider, useDispatch, useSelector } from "react-redux";

import { Route, Routes } from "react-router-dom";

import "./styles/globals.scss";
import "../src/index.css";

import { Suspense, useEffect, useState } from "react";
import Loader from "components/Loader";

import { useAuthModule } from "@data-phone/react-resource-server-auth"
import { setUserData } from "redux/slices/userSlice";
import routes from "routes";
import Header from "components/Header/Header";
import { getProviders } from "redux/slices/providers";



function App() {
  const [loader, setLoader] = useState(true);
  const dispatch = useDispatch();
  const { logout, data } = useAuthModule()

  useEffect(() => {
    if(!loader){
      dispatch(getProviders())
    }
  },[loader])
  useEffect(() => {
    if (data.isAuthenticated) {
      setLoader(false);
      dispatch(setUserData(data.sessionData));
    }
  }, [data])


  if (loader) {
    return (
      <Loader />
    );
  }


  return (
    <>
      <Header logout={logout} />
      <Routes>
        {routes.map((routes, index) => {
          const { path, component: Component } = routes;
          return (
            <Route
              key={index}
              path={path}
              element={
                <Suspense fallback={<Loader />}>
                  <Component />
                </Suspense>
              }
            />
          );
        })}
      </Routes>
    </>
  );
}

export default App;
