import React, { useEffect, useState } from "react";
import { MdClose, MdOutlineSearch } from "react-icons/md";


const SearchBox = ({filterContacts}) => {
  const [searchString, setSearchString] = useState("");
  useEffect(()=>{
    filterContacts(searchString)
  },[searchString])
  return (
    <div className="px-8">
      <form
        onSubmit={(e) => e.preventDefault()}
        className="bg-[#f1f3f4] focus-within:bg-white focus-within:shadow-md transition-all duration-500 ease-in-out flex items-center rounded-lg px-5 gap-5 relative"
      >
        <MdOutlineSearch className="shrink-0" color="#5f6368" size={22} />
        <input
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)
          
          }
          className="bg-transparent py-2 text-lg focus:outline-none"
          placeholder="Search"
        />
        <button
          type="reset"
          disabled={searchString === ""}
          onClick={() => setSearchString("")}
        >
          <MdClose
            className={`shrink-0 absolute top-0 right-6 h-full ${
             ""
            }`}
            color="#5f6368"
            size={22}
          />
        </button>
      </form>
    </div>
  );
};

export default SearchBox;
