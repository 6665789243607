import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ico_apps from "../../images/images/ico-apps.svg";
import ico_user from "../../images/images/ico-user.svg";

const Header = ({
  logout
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const usersData = useSelector((state) => state.usersDataSlice);

  const toggleMenuHandler = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <header>
      <div className="flex items-center place-content-between px-7 py-4 border-b">
        <div>
          <Link to={"/"}>
            <h1 className="text-xl font-bold"> DATAPHONE</h1>
          </Link>
        </div>
        <div className="flex items-center">
          <div className="pr-6">
            <img src={ico_apps} alt="" />
          </div>
          <div className="flex items-center">
            <img src={ico_user} alt="" />
       
            <div className="relative inline-block text-left">
              <div className="pl-2" style={{ cursor: "pointer" }}>
                {" "}
                <span onClick={toggleMenuHandler} className="pr-4">
                  {" "}
                  {usersData?.data?.firstName}
                </span>{" "}
              </div>

              <div
                className={`absolute  ${
                  toggleMenu ? "" : "hidden"
                }  right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
              >
                <div className="py-1" role="none">
             
                  <button
                    // type="submit"
                    className="text-gray-700 block w-full px-4 py-2 text-left text-sm"
                    role="menuitem"
                    tabindex="-1"
                    id="menu-item-3"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Sign out
                  </button>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
