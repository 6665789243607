import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from "react-redux";
import { store ,persistor} from "redux/slices";

import { AxiosInterceptor } from "../src/axios/index";

import App from "./App";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter as Router } from "react-router-dom";



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <AxiosInterceptor>
      <App />
    </AxiosInterceptor>
    </PersistGate>
  </Provider>
  </Router>
);
