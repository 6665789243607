import { useDispatch, useSelector } from "react-redux";
import ContactCard from "./ContactCard";
import ContactHeader from "./ContactHeader";
import { useEffect, useState } from "react";
import { getUserContacts } from "redux/slices/contacts";
import { formatPhoneNumber } from "helper";

const ContactList = ({ }) => {
  let contactsData = useSelector((state) => state.contacts);
  const [contacts, setContacts] = useState([]);
  const [loader, setLoader] = useState(false);
  const {data:providers} = useSelector((state) => state.providers);



  useEffect(() => {
    if (contactsData.data) {
      setContacts(contactsData.data);
    }
  }, [contactsData]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (contactsData.data && contactsData.data.length == 0) {
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } else {
      setLoader(false);
    }
  }, [contactsData]);

  useEffect(() => {
    dispatch(getUserContacts());
  }, []);

  const filterContacts = (value) => {
    if (value == "") {
      setContacts(contactsData.data);
    } else {
      if (contactsData.data) {
        let contacts = [...contactsData.data]
        let filteredContacts = contacts.filter((contact) => {
          return `${contact.first_name || ""}`.toLowerCase().includes(value.toLowerCase()) || `${contact.middle_name || ""}`.toLowerCase().includes(value.toLowerCase()) || `${contact.last_name || ""}`.toLowerCase().includes(value.toLowerCase())  || contact.phones.some(num => num.phone_number.includes(value))
        });
        setContacts(filteredContacts);
      }
    }
  };

  return (
    <div>
      <ContactHeader filterContacts={filterContacts} />
      <div className="relative px-4  ">
        <table className="w-full border-separate">
          <thead className="sticky top-16 z-10 bg-white border-b">
            <tr>
              <th className="w-1/4 text-left font-normal py-3 border-b px-4">
                Name
              </th>
              <th className="w-1/4 text-left font-normal py-3 border-b px-4">
                Phone Number
              </th>
              <th className="w-1/4 text-left font-normal py-3 border-b px-4 ">
                Contact key
              </th>
              <th className="w-1/4 text-left font-normal py-3 border-b px-4 ">
                Type
              </th>
              <th className="w-1/4 text-left font-normal py-3 border-b px-4 ">
                Action
              </th>
            </tr>
          </thead>
          <div className="my-2 mt-4">
            <p className="uppercase text-xs font-medium opacity-[0.56] px-4">
              Contacts{" "}
              {contacts && contacts.length > 0 ? `(${contacts.length})` : "(0)"}
            </p>
          </div>
          {contacts && contacts.length > 0 && (
            <tbody>
              {contacts &&
                contacts.length > 0 &&
                contacts.map((contact) => (
                  <ContactCard
                    provider_name={providers.find((provider) => provider.id == contact.provider_id)?.name}
                    key={contact.id}
                    id={contact.id}
                    contact_key={contact.contact_key}
                    name={contact.first_name || "" + " " + contact.middle_name || "" + " " + contact.last_name || ""  }
                    type={contact.contact_source}
                    number={
                      contact.phones &&
                        contact.phones[0] &&
                        contact.phones[0].phone_number
                        ? formatPhoneNumber(contact.phones[0].phone_number)
                        : ""
                    }
                  />
                ))}
              <>{/* Add more skeleton rows as needed */}</>
            </tbody>
          )}
          {((contacts &&
            contacts.length == 0 &&
            contactsData.status == "loading") ||
            loader) && (
              <div
                role="status"
                style={{ width: "113%", maxWidth: "113%" }}
                className="max-w-md p-4 space-y-divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700 md:p-6 "
              >
                <div className="flex items-center justify-between">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-300 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <br />
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>{" "}
                <br />{" "}
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>{" "}
                <br />{" "}
                <div className="flex items-center justify-between pt-4">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                </div>
                <span className="sr-only">Loading...</span>
              </div>
            )}
        </table>
      </div>
    </div>
  );
};

export default ContactList;
