import { useEffect, useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { MdOutlinePhone, MdPersonOutline } from "react-icons/md";
import { IoLocationOutline } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { IoIosAdd } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { ImSpinner8 } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import {  updateContact } from "redux/slices/contacts";

import { getSingleContact } from "./APIs/contactApis";
import Cookies from "js-cookie";
import { parsePhoneNumber } from "libphonenumber-js";

const EditContact = () => {
  const contactsStore = useSelector((state) => state.contacts);
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [name, setName] = useState({
    first_name: "",
    last_name: "",
    middle_name: ""
  });
  const [numbers, setNumbers] = useState([""]); // Array to store phone numbers
  const [addresses, setAddresses] = useState([]);
  const [emails, setEmails] = useState([]); // Array to store email addresses [2
  const [providerId, setProviderId] = useState(null);
  const [isGoogleContact, setIsGoogleContact] = useState(false);
  const [isPrivateContact, setIsPrivateContact] = useState(false);
  const contact = useSelector((state) =>
    state.contacts.data.find((contact) => contact.id == params.id)
  );

  const getContact = async (id) => {
    try {
      let singleContact = await getSingleContact(id, params.type);
      if (singleContact.data) {
        setProviderId(singleContact.data.provider_id);
        setName({
          first_name: singleContact.data.first_name,
          last_name: singleContact.data.last_name,
          middle_name: singleContact.data.middle_name
        });
        setNumbers(singleContact.data.phones.map((num) => num.phone_number));
        setEmails(singleContact.data.emails.map((email) => email.email));
        setAddresses(
          singleContact.data.addresses.map((address) => {
            return {
              address1: address.address1,
              address2: address.address2,
              zipCode: address.ZIP,
              city: address.city,
            };
          })
        );
        setIsPrivateContact(singleContact.data.is_private);
        setIsGoogleContact(
          singleContact.data.contact_source == "GOOGLE" ? true : false
        );
        // setContactData(singleContact.data)
      }
      return singleContact;
    } catch (error) {
      console.log(error);
      cogoToast.error(
        error.response.data.message
          ? error.response.data.message
          : "Some error occured. Please try again later."
      );
      navigate("/");
      throw error;
    }
  };
  useEffect(() => {
    if (contact) {
      setName({
        first_name: contact.first_name,
        last_name: contact.last_name,
        middle_name: contact.middle_name
      });
      setNumbers(contact.phones.map((num) => num.phone_number));
      setEmails(contact.emails.map((email) => email.email));
      setAddresses(
        contact.addresses.map((address) => {
          return {
            address1: address.address1,
            address2: address.address2,
            zipCode: address.ZIP,
            city: address.city,
          };
        })
      );
      setProviderId(contact.provider_id);
      setIsPrivateContact(contact.is_private);
      setIsGoogleContact(contact.contact_source == "GOOGLE" ? true : false);
    } else {
      getContact(params.id);
    }
  }, [contact]);

  const handleSubmit = () => {
    try {
      if (numbers.some(num => !parsePhoneNumber(num).isValid())) {
        return cogoToast.error("Invalid phone number");
      }
    } catch (error) {
      return cogoToast.error("Invalid phone number");
    }
    if (name.first_name == "") {
      return cogoToast.error("Please enter name");
    }

  
    let contactData = {
      provider_id:providerId,
      id: params.id,
      is_private: isPrivateContact,
      phones: numbers.map((num) => {
        return { phone_number: num };
      }),
      first_name: name.first_name,
      last_name: name.last_name,
      middle_name: name.middle_name,
      emails: emails.map((email) => {
        return { email: email };
      }),
      addresses: addresses.map((address) => {
        return {
          address1: address.address1,
          address2: address.address2,
          city: address.city,
          ZIP: address.ZIP,
        };
      }),
    };

    let data = {
      contactData: contactData,
      id: params.id,
      navigate: navigate,
    };
    dispatch(updateContact(data));

  };

  const handleAddNumber = () => {
    setNumbers([...numbers, ""]); // Add an empty string to the numbers array
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]); // Add an empty string to the emails array [3
  };

  const handleIsPrivateContact = (e) => {
    setIsPrivateContact(e.target.checked);
  };

  const handleEmailChange = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  const handleRemoveEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
  };

  const handleNumberChange = (index, value) => {
    const updatedNumbers = [...numbers];
    updatedNumbers[index] = value;
    setNumbers(updatedNumbers);
  };

  const handleRemoveNumber = (index) => {
    const updatedNumbers = [...numbers];
    updatedNumbers.splice(index, 1);
    setNumbers(updatedNumbers);
  };

  const handleAddAddress = () => {
    setAddresses([
      ...addresses,
      { address1: "", address2: "", zipCode: "", city: "" },
    ]);
  };

  const handleAddressChange = (index, field, value) => {
    const updatedAddresses = [...addresses];
    updatedAddresses[index][field] = value;
    setAddresses(updatedAddresses);
  };

  const handleRemoveAddress = (index) => {
    const updatedAddresses = [...addresses];
    updatedAddresses.splice(index, 1);
    setAddresses(updatedAddresses);
  };

  const handleNameChange = (e) => {
    setName({ ...name, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-8">
      <h1 className="mb-6 text-lg font-medium">Edit Contact</h1>
      <div className="flex justify-between flex-col sm:flex-row">
        <div className="flex gap-8 flex-col sm:flex-row">
          <div className="flex gap-8">
            <div className="pt-2">
              <button onClick={() => navigate("/")}>
                <IoMdArrowBack size={28} />
              </button>
            </div>
            <img
              className="rounded-full w-44 h-44"
              src={
                name
                  ? `https://ui-avatars.com/api/?name=${name.first_name[0]}&length=1&background=random&size=262`
                  : "https://ssl.gstatic.com/s2/oz/images/sge/grey_silhouette.png"
              }
            />
          </div>
        </div>
      </div>
      <hr className="my-8" />
      <div>
        <form
          className="max-w-[520px]"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <div className="flex gap-4 my-8 w-full items-center">
            <MdPersonOutline size={28} className="opacity-[0.56]" />
            <input
              value={name.first_name}
              onChange={handleNameChange}
              className="border-b w-full focus:outline-none leading-8"
              placeholder="First Name"
              name="first_name"
            />
          </div>
          <div className="flex gap-4 my-8 w-full items-center">
            <div style={{ width: "28px" }}></div>
            <input
              value={name.middle_name}
              onChange={handleNameChange}
              className="border-b w-full focus:outline-none leading-8"
              placeholder="Middle Name"
              name="middle_name"
            />
          </div>
          <div className="flex gap-4 my-8 w-full items-center">
            <div style={{ width: "28px" }}></div>
            <input
              value={name.last_name}
              onChange={handleNameChange}
              className="border-b w-full focus:outline-none leading-8"
              placeholder="Surname"
              name="last_name"
            />
          </div>


          {/* Phone number input fields */}
          {numbers.map((number, index) => (
            <div
              key={index}
              className="flex w-full gap-4 my-8 w-full items-center"
            >
              {/* <MdOutlinePhone size={28} className="opacity-[0.56]" /> */}
              <PhoneInput
                placeholder="Phone number"
                value={number}
                defaultCountry={"us"}
                onChange={(value) => handleNumberChange(index, value)}
              />
              {index > 0 && ( // Show remove button for additional phone numbers
                <button
                  type="button"
                  onClick={() => handleRemoveNumber(index)}
                  className="text-red-500"
                >
                  <TiDeleteOutline size={28} />
                </button>
              )}
            </div>
          ))}

          {/* Button to add more phone numbers */}
          <button
            type="button"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded flex items-center justify-center"
            onClick={handleAddNumber}
            style={{ backgroundColor: "#F0F4F9", borderRadius: "25px" }}
            aria-label="Add phone"
          >
            <IoIosAdd size={28} className="mr-1" style={{ color: "#0C56D0" }} />
            <span style={{ color: "#0C56D0" }}>Add Phone</span>
          </button>

          <br />

          {/* Email input fields */}
          {emails.map((email, index) => (
            <div
              key={index}
              className="flex w-full gap-4 my-8 w-full items-center"
            >
              {/* <MdOutlinePhone size={28} className="opacity-[0.56]" /> */}
              <input
                value={email}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                className="border-b w-full focus:outline-none leading-8"
                placeholder="Email"
              />
              {index > 0 && ( // Show remove button for additional phone numbers
                <button
                  type="button"
                  onClick={() => handleRemoveEmail(index)}
                  className="text-red-500"
                >
                  <TiDeleteOutline size={28} />
                </button>
              )}
            </div>
          ))}

          {/* Button to add more email addresses */}
          <button
            type="button"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded flex items-center justify-center"
            onClick={handleAddEmail}
            style={{ backgroundColor: "#F0F4F9", borderRadius: "25px" }}
            aria-label="Add email"
          >
            <IoIosAdd size={28} className="mr-1" style={{ color: "#0C56D0" }} />
            <span style={{ color: "#0C56D0" }}>Add Email</span>
          </button>

          <br />
          {addresses.map((address, index) => (
            <div>
              <div
                key={index}
                className="flex w-full gap-4 my-8 w-full items-center"
              >
                <IoLocationOutline size={28} className="opacity-[0.56]" />
                <input
                  value={address.address1}
                  onChange={(e) =>
                    handleAddressChange(index, "address1", e.target.value)
                  }
                  className="border-b w-full focus:outline-none leading-8"
                  placeholder="Address 1"
                />
                {/* {index > 0 && ( */}
                <button
                  type="button"
                  onClick={() => handleRemoveAddress(index)}
                  className="text-red-500"
                >
                  <TiDeleteOutline size={28} />
                </button>
                {/* )} */}
              </div>
              <div
                key={index}
                className="flex w-full gap-4 my-8 w-full items-center"
              >
                <div style={{ width: "28px" }}></div>

                <input
                  value={address.address2}
                  onChange={(e) =>
                    handleAddressChange(index, "address2", e.target.value)
                  }
                  className="border-b w-full focus:outline-none leading-8"
                  placeholder="Address 2"
                />
              </div>
              <div
                key={index}
                className="flex w-full gap-4 my-8 w-full items-center"
              >
                <div style={{ width: "28px" }}></div>

                <input
                  value={address.zipCode}
                  onChange={(e) =>
                    handleAddressChange(index, "zipCode", e.target.value)
                  }
                  className="border-b w-full focus:outline-none leading-8"
                  placeholder="Zip Code"
                />
              </div>
              <div
                key={index}
                className="flex w-full gap-4 my-8 w-full items-center"
              >
                <div style={{ width: "28px" }}></div>

                <input
                  value={address.city}
                  onChange={(e) =>
                    handleAddressChange(index, "city", e.target.value)
                  }
                  className="border-b w-full focus:outline-none leading-8"
                  placeholder="City"
                />
              </div>
            </div>
          ))}

          {/* Button to add more addresses */}
          <button
            type="button"
            className="w-full px-4 py-2 bg-blue-500 text-white rounded flex items-center justify-center"
            onClick={handleAddAddress}
            style={{ backgroundColor: "#F0F4F9", borderRadius: "25px" }}
            aria-label="Add address"
          >
            <IoIosAdd size={28} className="mr-1" style={{ color: "#0C56D0" }} />
            <span style={{ color: "#0C56D0" }}>Add Address</span>
          </button>
          <br />
          <br />
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              checked={isPrivateContact}
              type="checkbox"
              value=""
              className="sr-only peer"
              onChange={handleIsPrivateContact}
            />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ml-5" style={{ color: "#0C56D0" }}>
              Make Contact Private
            </span>
          </label>
          <br />
          <br />
          <div className="">
            <button
              type="submit"
              disabled={contactsStore.status == "loading" || numbers.length === 0 || numbers.some((number) => number.number === "")}
              className="h-10 bg-[#1a73e8] text-white px-8 rounded disabled:grayscale w-full flex items-center justify-center rounded"
            >
              {contactsStore.status == "loading" && (
                <span>
                  <ImSpinner8 className="spin" />
                </span>
              )}
              {contactsStore.status != "loading" && <span>Update Contact</span>}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditContact;
