import { api } from "@data-phone/react-resource-server-auth";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import cogoToast from "cogo-toast";

export const generateGoogleLink = async (redirect_uri)=>{
  const response = await api.get(
    `${process.env.REACT_APP_CONTACTS_URL}/auth/authorize?redirect_uri=${redirect_uri}`,
  );
  window.location.href = response.data.url;
}

export const googleAuthLogin = createAsyncThunk(
  "items/fetchGoogleAuth",
  async (data) => {
    const response = await generateGoogleLink(data.redirect_uri);
    return response.data;
  }
);



const initialState = {
  data: {},
  status: "idle",
  error: null,
};

const googleAuthSlice = createSlice({
  name: "googleAuth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(googleAuthLogin.pending, (state) => {
        state.status = "loading";
        cogoToast.loading(
          "You will be redirected to the Google consent page shortly.",
          { hideAfter: 5 }
        );
      })
      .addCase(googleAuthLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload) {
          window.location.href = action.payload.url;
        }
      })
      .addCase(googleAuthLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        cogoToast.error("Something went wrong. Please try again later.");
      })
    
  },
});

export default googleAuthSlice.reducer;
