import React from "react";
import { MdOutlinePhone } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { formatPhoneNumber } from "helper";



const PhoneView = ({ contact }) => {
  return (
    <div className="border p-4 flex flex-col gap-4 max-w-[520px] rounded-xl " style={{background:"#F0F4F9"}}>
      <h2 className="font-medium">Contact Details</h2>
      {
        contact?.phones && contact?.phones.length > 0 &&
        contact?.phones.map((phone, index) => (
          <div className="flex gap-4 items-center">
            {
              index == 0 ? <MdOutlinePhone className="opacity-[0.36]" size={28}/> : <div style={{ width: '28px' }}></div>
            }
            <a href={`tel:${phone?.phone_number}`} className="text-blue-500">
               {phone?.phone_number && formatPhoneNumber(phone?.phone_number)}
            </a>
          </div>
        ))
      }
      {
        contact?.emails && contact?.emails.length > 0 &&
        contact?.emails.map((email, index) => (
          <div className="flex gap-4 items-center">
            {
              index == 0 ? <CiMail className="opacity-[0.36]"size={28} /> : <div style={{ width: '28px' }}></div>
            }
            <a href={`mailto:${email.email}`} className="text-blue-500">
              {email.email}
            </a>
          </div>
        ))
      }

      {
        contact?.addresses && contact?.addresses.length > 0 &&
        contact?.addresses.map((address, index) => (
          <div>
            <div className="flex gap-4 items-center">
                <CiLocationOn className="opacity-[0.36]" size={28} />
              <span href={`{address?.address}`} className="text-blue-500">
                {address?.address1}
              </span>
            </div>
            <div className="flex gap-4 items-center">
            <div style={{ width: '28px' }}></div>
              <span href={`{address?.address}`} className="text-blue-500">
                {address?.address2}
              </span>
            </div>
            <div className="flex gap-4 items-center">
            <div style={{ width: '28px' }}></div>
              <span href={`{address?.address}`} className="text-blue-500">
                {address?.city}
              </span>
            </div>
            <div className="flex gap-4 items-center">
            <div style={{ width: '28px' }}></div>
              <span href={`{address?.address}`} className="text-blue-500">
                {address?.ZIP}
              </span>
            </div>
          </div>
        ))
      }
      <h2 className="font-medium">Contact Key</h2>
      <p>{contact?.contact_key}</p>
    </div>
  );
};

export default PhoneView;
