import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  data: {},
};

// Define the slice
const usersDataSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUserData: (state, action) => {
      state.data = action.payload;
    }
  },
});

export const { setUserData } = usersDataSlice.actions;
export default usersDataSlice.reducer;
